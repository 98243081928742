import React, { useState, useEffect } from 'react';
import './index.css'; 
import image000001 from './images/000001.jpg';
import image000002 from './images/000002.jpg';
import image000003 from './images/000003.jpg';
import image000004 from './images/000004.jpg';
import image000005 from './images/000005.jpg';
import image000006 from './images/000006.jpg';
import image000007 from './images/000007.jpg';
import image000008 from './images/000008.jpg';
import image000009 from './images/000009.jpg';
import image000010 from './images/000010.jpg';
import image000011 from './images/000011.jpg';
import image000012 from './images/000012.jpg';
import image000013 from './images/000013.jpg';
import image000014 from './images/000014.jpg';
import image000015 from './images/000015.jpg';
import image000016 from './images/000016.jpg';
import image000017 from './images/000017.jpg';
import image000018 from './images/000018.jpg';
import image000019 from './images/000019.jpg';
import image000020 from './images/000020.jpg';
import image000021 from './images/000021.jpg';
import image000022 from './images/000022.jpg';
import image000023 from './images/000023.jpg';
import image000024 from './images/000024.jpg';
import image000025 from './images/000025.jpg';
import image000026 from './images/000026.jpg';
import image000027 from './images/000027.jpg';
import image000028 from './images/000028.jpg';
import image000029 from './images/000029.jpg';
import image000030 from './images/000030.jpg';
import image000031 from './images/000031.jpg';
import image000032 from './images/000032.jpg';
import image000033 from './images/000033.jpg';
import image000034 from './images/000034.jpg';
import image000035 from './images/000035.jpg';
import image000036 from './images/000036.jpg';
import image000037 from './images/000037.jpg';
import image000038 from './images/000038.jpg';
import image000039 from './images/000039.jpg';
import image000040 from './images/000040.jpg';
import image000041 from './images/000041.jpg';
import image000042 from './images/000042.jpg';
import image000043 from './images/000043.jpg';
import image000044 from './images/000044.jpg';
import image000045 from './images/000045.jpg';
import image000046 from './images/000046.jpg';
import image000047 from './images/000047.jpg';
import image000048 from './images/000048.jpg';
import image000049 from './images/000049.jpg';
import image000050 from './images/000050.jpg';
import Text from './Text';

const images = {
  '000001': image000001,
  '000002': image000002,
  '000003': image000003,
  '000004': image000004,
  '000005': image000005,
  '000006': image000006,
  '000007': image000007,
  '000008': image000008,
  '000009': image000009,
  '000010': image000010,
  '000011': image000011,
  '000012': image000012,
  '000013': image000013,
  '000014': image000014,
  '000015': image000015,
  '000016': image000016,
  '000017': image000017,
  '000018': image000018,
  '000019': image000019,
  '000020': image000020,
  '000021': image000021,
  '000022': image000022,
  '000023': image000023,
  '000024': image000024,
  '000025': image000025,
  '000026': image000026,
  '000027': image000027,
  '000028': image000028,
  '000029': image000029,
  '000030': image000030,
  '000031': image000031,
  '000032': image000032,
  '000033': image000033,
  '000034': image000034,
  '000035': image000035,
  '000036': image000036,
  '000037': image000037,
  '000038': image000038,
  '000039': image000039,
  '000040': image000040,
  '000041': image000041,
  '000042': image000042,
  '000043': image000043,
  '000044': image000044,
  '000045': image000045,
  '000046': image000046,
  '000047': image000047,
  '000048': image000048,
  '000049': image000049,
  '000050': image000050,
};

const Main = () => {
  const [currentImage, setCurrentImage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const keys = Object.keys(images);
    const randomIndex = Math.floor(Math.random() * keys.length);
    setCurrentImage(images[keys[randomIndex]]);
  }, []);

  const [seenImages, setSeenImages] = useState(new Set());

  useEffect(() => {
    if (seenImages.size === Object.keys(images).length) {
      setSeenImages(new Set());
    }
  }, [seenImages]);

  const generateRandomImage = () => {
    const keys = Object.keys(images);
    let randomKey;

    do {
      const randomIndex = Math.floor(Math.random() * keys.length);
      randomKey = keys[randomIndex];
    } while (seenImages.has(randomKey));

    setLoading(true);

    setTimeout(() => {
      setCurrentImage(images[randomKey]);
      setSeenImages(new Set(seenImages.add(randomKey)));
      setLoading(false);
    }, 500); // Simulate loading delay
  };

  const downloadFilename = `random-image-${Date.now()}.jpg`;

  return (
    <main className='py-5'>
      <div className="flex flex-col justify-center items-center text-center">
        <h1 className='text-2xl font-bold mb-5'> <span className='text-blue-600 font-bold text-3xl'>Random</span> <span className='text-red-600 font-bold text-3xl'>Image</span> <span className='text-indigo-600 font-bold text-3xl'>Generator</span> </h1>
        <div className="mt-4 image-container">
          {loading ? (
            <div className="spinner">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          ) : (
            <img 
              src={currentImage} 
              alt="Displayed" 
              className="w-full h-full object-contain" 
              onLoad={() => setLoading(false)}
            />
          )}
        </div>
        <div className="mt-4 flex flex-col md:flex-row gap-4">
          <button
            onClick={generateRandomImage}
            className="px-4 py-2 bg-blue-500 text-white rounded"
          >
            Generate Random Image
          </button>
          <a
            href={currentImage}
            download={downloadFilename}
            className="px-4 py-2 bg-green-500 text-white rounded"
          >
            Download Image
          </a>
        </div>
      </div>
      <Text/>
    </main>
  );
}

export default Main;
