import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="bg-white">
      <div className="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
        <div className="flex h-16 items-center justify-between">
          <div className="flex-1 md:flex md:items-center md:gap-12">
            <a className="block" href="#">
              <span className="sr-only">Home</span>
              <Link to="/">
              <div className="flex">
              
                <img
                  className="h-[60px]"
                  src="https://cdn-icons-png.flaticon.com/128/4140/4140048.png"
                  alt=""
                />
                <span className="text-sm md:text-xl font-semibold mt-4 px-1">
                  Random Face Generator
                </span>
                
              </div>
              </Link>
            </a>
          </div>

          <div className="md:flex  md:gap-12 mt-7">
            {/* Desktop Navigation */}
            <nav aria-label="Global" className="hidden md:block">
              <ul className="flex items-center gap-6 text-sm">
                <Link to="/">
                  <li>
                    <p className="text-[16px]">Home</p>
                  </li>
                </Link>
                <Link to="/about">
                  <li>
                    <p className="text-[16px]">About</p>
                  </li>
                </Link>
                <Link to="/contact">
                  <li>
                    <p className="text-[16px]">Contact</p>
                  </li>
                </Link>
                <Link to="/privacy-policy">
                  <li>
                    <p className="text-[16px]">Privacy Policy</p>
                  </li>
                </Link>
                <Link to="/terms-and-conditions">
                  <li>
                    <p className="text-[16px]">Terms and Conditions</p>
                  </li>
                </Link>
              </ul>
              
            </nav>

            {/* Mobile Menu Toggle Button */}
            <div className="block md:hidden">
              <button
                className="rounded bg-gray-100 p-2 text-gray-600 transition hover:text-gray-600/75"
                onClick={toggleMenu}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

        {/* Mobile Navigation Menu */}
        <div
          className={`fixed inset-0 z-50 bg-white shadow-lg transition-transform transform ${
            isMenuOpen ? 'translate-x-0' : 'translate-x-full'
          } md:hidden`}
        >
          <div className="flex justify-end p-4">
            <button
              className="text-gray-600"
              onClick={toggleMenu}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <nav aria-label="Global" className="flex flex-col items-center mt-6">
            <Link to="/" onClick={toggleMenu} className="py-2 text-lg">
              Home
            </Link>
            <Link to="/about" onClick={toggleMenu} className="py-2 text-lg">
              About
            </Link>
            <Link to="/privacy-policy" onClick={toggleMenu} className="py-2 text-lg">
              Privacy Policy
            </Link>
            <Link to="/terms-and-conditions" onClick={toggleMenu} className="py-2 text-lg">
              Terms and Conditions
            </Link>
            <Link to="/contact" onClick={toggleMenu} className="py-2 text-lg">
              Contact
            </Link>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
