import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    

<footer className="bg-white rounded-lg shadow dark:bg-gray-900 m-4">
    <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
        <div className="sm:flex sm:items-center sm:justify-between">
            <a href="/" className="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse">
            <div className='flex'>
          <img className='h-[60px]' src="https://cdn-icons-png.flaticon.com/128/4140/4140048.png" alt="" />
          <span className='text-sm md:text-xl font-semibold mt-4 px-1'>Random Face Generator</span>
          </div>
            </a>
            <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
               <Link to="/about">
                <li>
                    <p className="hover:underline me-4 md:me-6">About</p>
                </li>
                </Link>
                <Link to="/contact">
                <li>
                    <p  className="hover:underline me-4 md:me-6">Contact</p>
                </li>
                </Link>
                <Link to="/privacy-policy">
                <li>
                    <p className="hover:underline me-4 md:me-6">Privacy Policy</p>
                </li></Link>
                <Link to="/terms-and-conditions">
                <li>
                    <p  className="hover:underline">Tems and Conditionis</p>
                </li>
                </Link>
            </ul>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2024 <a href="/" className="hover:underline">Random Face Generator</a>. All Rights Reserved.</span>
    </div>
</footer>


  )
}

export default Footer