import React from 'react'

const Text = () => {
  return (
    <div>
        <div class="container mx-auto py-10 px-4">
  <h1 class="text-4xl font-bold text-center mb-8">Welcome to the Random Image Generator</h1>

  <p class="text-lg text-center mb-6">
    Discover a world of random images at the click of a button! Whether you're looking for inspiration, a fun visual break, or a random image for your next project, our Random Image Generator is here to help.
  </p>

  <h2 class="text-2xl font-semibold mb-4">Why Use Our Random Image Generator?</h2>
  <ul class="list-disc list-inside mb-6">
    <li class="text-lg">Hundreds of high-quality images</li>
    <li class="text-lg">Completely free to use</li>
    <li class="text-lg">Instant downloads available</li>
    <li class="text-lg">No sign-up required</li>
  </ul>

  <h2 class="text-2xl font-semibold mb-4">How It Works</h2>
  <p class="text-lg mb-4">
    Using the Random Image Generator is easy. Simply click on the "Generate Random Image" button, and a new image will be displayed instantly. You can download the image directly to your device with just one more click.
  </p>

  <h2 class="text-2xl font-semibold mb-4">Features</h2>
  <ul class="list-disc list-inside mb-6">
    <li class="text-lg">Wide variety of images: From nature to abstract art, our generator offers a diverse selection of images.</li>
    <li class="text-lg">User-friendly interface: Simple and intuitive design for easy navigation.</li>
    <li class="text-lg">Responsive design: Enjoy a seamless experience on any device, whether it's a desktop, tablet, or smartphone.</li>
    <li class="text-lg">High-resolution images: All images are high-quality and suitable for any project.</li>
  </ul>

  <h2 class="text-2xl font-semibold mb-4">Get Started Now!</h2>
  <p class="text-lg mb-6">
    Ready to explore? Click the button below to generate your first random image. Who knows what amazing picture you'll discover next!
  </p>

  
</div>

    </div>
  )
}

export default Text