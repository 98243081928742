import React from 'react'
import Main from './Main'
import { BrowserRouter,Route, Routes, Link } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';
import About from './About'
import Contact from './Contact'
import Privacy from './Privacy'
import Terms from './Terms'

const App = () => {
  return (
    <BrowserRouter>
    <section>
      <Navbar/>
      <div>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/terms-and-conditions" element={<Terms />} />
      </Routes>
      </div>
      <Footer/>
    </section>
    </BrowserRouter>
  )
}

export default App