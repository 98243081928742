import React from 'react';

const About = () => {
  return (
    <div>
      <h2>About Us!</h2>
      <h3 style={{ textAlign: 'center' }}>
        Welcome To <span id="W_Name1">Random Face Generator</span>
      </h3>
      <p>
        <span id="W_Name2">Random Face Generator</span> is a professional{' '}
        <span id="W_Type1">Random Face Generator</span> platform. Here we will
        provide you only interesting content, which you will like very much.
        We're dedicated to providing you the best of{' '}
        <span id="W_Type2">Random Face Generator</span>, with a focus on
        dependability and <span id="W_Spec">Random Face Generator</span>.
        We're working to turn our passion for{' '}
        <span id="W_Type3">Random Face Generator</span> into a booming{' '}
        <a
          href="https://www.blogearns.com/2021/05/free-about-us-page-generator.html"
          rel="noopener noreferrer"
          style={{ color: 'inherit', textDecoration: 'none' }}
        >
          online website
        </a>
        . We hope you enjoy our{' '}
        <span id="W_Type4">Random Face Generator</span> as much as we enjoy
        offering them to you.
      </p>
      <p>
        I will keep posting more important posts on my website for all of you.
        Please give your support and love.
      </p>
      <p
        style={{
          fontWeight: 'bold',
          textAlign: 'center',
        }}
      >
        Thanks For Visiting Our Site
        <br />
        <br />
        <span
          style={{
            color: 'blue',
            fontSize: '16px',
            fontWeight: 'bold',
            textAlign: 'center',
            display: 'block',
          }}
        >
          Have a nice day!
        </span>
      </p>
    </div>
  );
};

export default About;
